@font-face {
  font-family: 'polgozar';
  src: url('./polgozar.eot?s0s3y9');
  src: url('./polgozar.eot?s0s3y9#iefix') format('embedded-opentype'), url('./polgozar.ttf?s0s3y9') format('truetype'),
    url('./polgozar.woff?s0s3y9') format('woff'), url('./polgozar.svg?s0s3y9#polgozar') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'polgozar' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar2:before {
  content: '\e917';
}
.icon-calendar-light:before {
  content: '\e90e';
}
.icon-expanded-add-circle:before {
  content: '\e91b';
}
.icon-add-doc:before {
  content: '\e901';
}
.icon-instagram:before {
  content: '\e903';
}
.icon-hyperlink:before {
  content: '\e904';
}
.icon-chat:before {
  content: '\e905';
}
.icon-dead:before {
  content: '\e90d';
}
.icon-birthday:before {
  content: '\e916';
}
.icon-plus:before {
  content: '\e928';
}
.icon-logo:before {
  content: '\e900';
}
.icon-group:before {
  content: '\e902';
}
.icon-add-circle:before {
  content: '\e906';
}
.icon-add-photo:before {
  content: '\e907';
}
.icon-arrow-up:before {
  content: '\e908';
}
.icon-arrow-left:before {
  content: '\e909';
}
.icon-arrow-down:before {
  content: '\e90a';
}
.icon-arrow-right:before {
  content: '\e90b';
}
.icon-audio:before {
  content: '\e90c';
}
.icon-checkbox:before {
  content: '\e90f';
}
.icon-check:before {
  content: '\e910';
}
.icon-chevron-up:before {
  content: '\e911';
}
.icon-chevron-right:before {
  content: '\e912';
}
.icon-chevron-down:before {
  content: '\e913';
}
.icon-chevron-left:before {
  content: '\e914';
}
.icon-close:before {
  content: '\e915';
}
.icon-facebook:before {
  content: '\e918';
}
.icon-gallery:before {
  content: '\e919';
}
.icon-google:before {
  content: '\e91a';
}
.icon-lock:before {
  content: '\e91d';
}
.icon-pen:before {
  content: '\e91e';
}
.icon-picture:before {
  content: '\e91f';
}
.icon-pin:before {
  content: '\e920';
}
.icon-rating-star:before {
  content: '\e921';
}
.icon-search:before {
  content: '\e922';
}
.icon-trash:before {
  content: '\e923';
}
.icon-twitter:before {
  content: '\e924';
}
.icon-unlock:before {
  content: '\e925';
}
.icon-user:before {
  content: '\e926';
}
.icon-video:before {
  content: '\e927';
}
